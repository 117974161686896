import axios from "axios";

export const LOGIN_URL = "http://127.0.0.1:8000/api/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}
export const products = () => dispatch => {
  axios.get('http://127.0.0.1:8000/api/landing/product')
  .then( sliderData => {
      dispatch({
          type: 'VIDEO_SUCSSESS',
          payload: sliderData.data
      })
  })
  .catch(err => {
      dispatch({
          type: 'VIDEO_ERR',
          payload: err
      })
      if(err.response) {
      }
  })
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
