import React, { Component } from "react";
import {connect} from 'react-redux';
import { Table } from "react-bootstrap";
import callAPI from './api';
import {toastr} from 'react-redux-toastr';
import MaterialTable from "material-table";
class ListCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countries : [],
     
    };
  }
  
  componentDidMount()
  {
    
  this.getdata()
    
  }

  getdata= async() =>
  {
    let response =await new callAPI().api("GET", "/country", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        countries : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }
  CreateCountry = async(country) =>
  {
    if(country.name == "")
    {
      toastr.error("Name Field Can't be Empty")
    }
    else
    {
    const body = {
      'name' : country.name
    }
    let response =await new callAPI().api("POST", "/country", {}, {}, body);
    if(response.status == 200)
    {
         toastr.success('Added Successfully');
         this.getdata()
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  }
  UpdateCountry = async(newData ,oldData)=>
  {
   if(newData.name == "")
   {
     toastr.error("Name Field Can't be Empty");
   }
   else{
    const body = {
      'name' : newData.name
    }
    let response =await new callAPI().api("PUT", "/country/"+oldData.id, {}, {}, body);
    if(response.status == 200)
    {
         toastr.success('Updated Successfully');
         this.getdata()
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  }
  DeleteCountry = async(country) =>
  {
   
    let response =await new callAPI().api("DELETE", "/country/"+country.id, {}, {}, {});
    if(response.status == 200)
    {
         toastr.success('Edited Successfully');
         this.getdata()
    }
    if(response.status == 202)
    {
      let error = await response.json()
    toastr.error(error.error)
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }


 

  render() {
    const columns = [ 
      { title: '#', field: 'id'  ,editable:'never'},
      { title: 'Name', field: 'name' }]
     
    
  const rows = this.state.countries.map(function(country) {
    return {
      id: country.id,
      name:  country.name,
      }
});
   
    const permissions = this.props.permissions;
    const self = this;
      return (
        <div>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
        
            
           
                  <div className="table-responsive">
              
          
              <MaterialTable
                    columns = {columns}
                    data = {rows}
                    title="Countries"
                    editable={{
                      onRowAdd: newData =>
                        this.CreateCountry(newData),
                      onRowUpdate: (newData, oldData) =>
                       this.UpdateCountry(newData,oldData),
                      onRowDelete: oldData =>
                      this.DeleteCountry(oldData),
                    }}
                    
                />
          </div>
                 
                </div>
              
          
        </div>
      );
    }
  }

const mapStateToProps = (state) => ({
  permissions : state.permissions
})
export default connect(mapStateToProps)(ListCountry);
