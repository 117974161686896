import React, { Component } from "react";
import callAPI from "./api";
import {toastr} from 'react-redux-toastr';

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      franchises: [],
    };
    this.getFranchises()
  }
 
  getFranchises= async() => {
    let response =await new callAPI().api("GET", "/franchise/"+this.props.match.params.id,  {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        franchises : await response.json(),
        loading : false
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
 
  render() {
    
    if (this.state.loading) {
      return <div className="loader"></div>;
    } else {
     
      return (
        <div>
        <div className="row">
          <div className="col">
            <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair kt-portlet--height-fluid">
              <div
                className="kt-portlet__head"
                style={{ height: "auto" }}
              >
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">
                  Personal Information
                  </h3>
              </div>
                  <div className="kt-portlet__head-toolbar">
                      
              </div>
              </div>

              <div className="kt-portlet__body">
                  
              <div className="kt-widget12">
			<div className="kt-widget12__content">
				<div className="kt-widget12__item">	
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">FullName</span> 
                         <span className="kt-widget12__value">{this.state.franchises.personal_info.firstname}{" "+this.state.franchises.personal_info.surname}</span>
					</div>
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Age</span> 
						<span className="kt-widget12__value">{this.state.franchises.personal_info.age ? this.state.franchises.personal_info.age : "None"}</span>
					</div>
					<div className="kt-widget12__info">
						<span className="kt-widget12__desc">Email</span> 
						<span className="kt-widget12__value">{this.state.franchises.personal_info.email ? this.state.franchises.personal_info.email : "None"}</span>	
					</div>
                 	 
				</div>
				<div className="kt-widget12__item">	
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Investment Capital</span> 
						<span className="kt-widget12__value">{"€"+this.state.franchises.personal_info.investment_capital ? this.state.franchises.personal_info.investment_capital : "" }</span>
					</div>
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Country</span> 
						<span className="kt-widget12__value">{this.state.franchises.personal_info.country ? this.state.franchises.personal_info.country : ""}</span>
					</div>

					<div className="kt-widget12__info">
						<span className="kt-widget12__desc">City</span> 
						<span className="kt-widget12__value">{this.state.franchises.personal_info.city ? this.state.franchises.personal_info.city : ""}</span>	
					</div>					 	 	 
				</div>
				<div className="kt-widget12__item">
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Mobile Number</span> 
                  <span className="kt-widget12__value">{this.state.franchises.personal_info.mobile_number ? this.state.franchises.personal_info.mobile_number : ""}</span>
					</div>
                    <div className="kt-widget12__info">
						<span className="kt-widget12__desc">Landline Number</span> 
						<span className="kt-widget12__value">{this.state.franchises.personal_info.landline_number ? this.state.franchises.personal_info.landline_number : ""}</span>	
					</div>
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Work Number</span> 
						<span className="kt-widget12__value">{this.state.franchises.personal_info.work_number ? this.state.franchises.personal_info.work_number : ""}</span>
					</div>
			
				</div>
			</div>
		</div>
                 
              </div>
            </div>
          </div>
          <div className="col">
            <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair kt-portlet--height-fluid">
              <div
                className="kt-portlet__head"
                style={{ height: "auto" }}
              >
                  <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">
                  Marital Status
                  </h3>
                  </div>
                  <div className="kt-portlet__head-toolbar">
                     
                  
              </div>
              
              </div>
              <div className="kt-portlet__body">
               <div className="kt-widget12">
			<div className="kt-widget12__content">
				<div className="kt-widget12__item">	
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Status</span> 
                         <span className="kt-widget12__value">{this.state.franchises.marital_status.status ? this.state.franchises.marital_status.status : "None"}</span>
					</div>
				</div>
				<div className="kt-widget12__item">	
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Has Children</span> 
						<span className="kt-widget12__value">{this.state.franchises.marital_status.children ? this.state.franchises.marital_status.children : "None"}</span>
					</div>				 	 	 
				</div>
				<div className="kt-widget12__item">
				
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Ages of Children</span> 
						<span className="kt-widget12__value">{this.state.franchises.marital_status.ages_of_children ? this.state.franchises.marital_status.ages_of_children : "None" }</span>
					</div>
			
				</div>
			
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
          <div className="kt-portlet__head" style={{ height: "auto" }}>
          <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">
                  Area of Interset
                  </h3>
              </div>
                   <div className="kt-portlet__head-toolbar">
                            
                  
                                </div>
          </div>
          <div className="kt-portlet__body">
          <div className="kt-widget12">
			<div className="kt-widget12__content">
				<div className="kt-widget12__item">	
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">City</span> 
                         <span className="kt-widget12__value">{this.state.franchises.area_of_interset.city ? this.state.franchises.area_of_interset.city : ""}</span>
					</div>
                   
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Country</span> 
						<span className="kt-widget12__value">{this.state.franchises.area_of_interset.country ? this.state.franchises.area_of_interset.country : ""}</span>
					</div>				 	 	 
				
				</div>
			
				<div className="kt-widget12__item">
				
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Store Status</span> 
						<span className="kt-widget12__value">{this.state.franchises.area_of_interset.store_status ? this.state.franchises.area_of_interset.store_status : "" }</span>
					</div>
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Is Store Avilable</span> 
						<span className="kt-widget12__value">{this.state.franchises.area_of_interset.is_store_avilable ? this.state.franchises.area_of_interset.is_store_avilable : ""}</span>
					</div>
			
				</div>
			
                   
                  </div>
                </div>
          </div>
        </div>

          </div>
          <div className='col-6'>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
          <div className="kt-portlet__head" style={{ height: "auto" }}>
          <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">
                  Store Availability
                  </h3>
              </div>
                   <div className="kt-portlet__head-toolbar">
                            
                  
                                </div>
          </div>
          <div className="kt-portlet__body">
          <div className="kt-widget12">
			<div className="kt-widget12__content">
				<div className="kt-widget12__item">	
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">City</span> 
                         <span className="kt-widget12__value">{this.state.franchises.store_availability.city}</span>
					</div>
                   
					<div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Address</span> 
						<span className="kt-widget12__value">{this.state.franchises.store_availability.address}</span>
					</div>	
                    <div className="kt-widget12__info">				 	 
                    <span className="kt-widget12__desc">Facade Length</span> 
                    <span className="kt-widget12__value">{this.state.franchises.store_availability.facade_length}</span>
                </div>			 	 	 
				
				</div>
			
				<div className="kt-widget12__item">
				
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Indoor Area</span> 
						<span className="kt-widget12__value">{this.state.franchises.store_availability.indoor_area }</span>
					</div>
                    <div className="kt-widget12__info">				 	 
						<span className="kt-widget12__desc">Basement/loft</span> 
						<span className="kt-widget12__value">{this.state.franchises.store_availability.basement_loft}</span>
					</div>
                    <div className="kt-widget12__info">				 	 
                    <span className="kt-widget12__desc">Exterior Area</span> 
                    <span className="kt-widget12__value">{this.state.franchises.store_availability.exterior_area }</span>
                </div>
			
				</div>
               
			
                   
                  </div>
                </div>
          </div>
        </div>

          </div>

        </div>
        
        {/* end::Widget 11 */}
      </div>
      );
    }
  }
}
