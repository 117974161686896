import React, { Component } from "react";
import {connect} from 'react-redux';
import callAPI from './api';
import CreateProduct from './CreateProduct'
import {toastr} from 'react-redux-toastr';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ImageUploader from 'react-images-upload';

import MaterialTable from "material-table";
class ListProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products : [],
     modal : false,
     showBody : true,
     showLoader : false,
     name : "",
     categories : [],
     description : "",
     pictures : [],
     category_id : '',
     product_id : ''
    };
  }
  
  componentDidMount()
  {
    
  this.getdata()
  this.getCategories()
    
  }
  getCategories = async() =>
  {
    let response =await new callAPI().api("GET", "/product-category", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        categories : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  }
  getdata= async() =>
  {
    let response =await new callAPI().api("GET", "/product", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        products : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }

  
  DeleteProduct = async(product) =>
  {
   
    let response =await new callAPI().api("DELETE", "/product/"+product.id, {}, {}, {});
    if(response.status == 200)
    {
         toastr.success('Edited Successfully');
         this.getdata()
    }
    else if(response.status == 202)
    {
      let error = await response.json()
    toastr.error(error.error)
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }

  handleSubmit =async (event) => {
    event.preventDefault();

    this.setState({
      showLoader: true,
      showBody: false
    });
  
    let data = new FormData();
    this.state.pictures.forEach((image_file) => {
      data.append('image', image_file,image_file.name);
    });
   
      data.append('name', this.state.name);
      data.append('description', this.state.description);
      data.append('price', this.state.price);
      data.append('product_category_id', this.state.category_id);
      const headers = {
        Authorization : 'Bearer '+JSON.parse(localStorage.getItem('user_token')),
        Accept : 'application/json',
      }
     let res = await fetch(process.env.REACT_APP_URL+'/api/product/'+this.state.product_id,{
        method : 'POST',
        headers:headers,
        body : data,
      
    })
      let response =await res;
   if (response.status == 200) {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false
        });
        this.getdata();
        toastr.success("Product Updated successfully!");
      } else if (response.status == 422 || response.status == 500) {
        this.setState({
          showLoader: false,
          showBody: true
        });
        toastr.error("invalid data");
      }
    
  }
  
  onDrop = async (picture,url) =>{
   
    await this.setState({
         pictures: this.state.pictures.concat(picture),
     });
 }

 

  render() {
        
    let data = {}
  this.state.categories.map((category)=>{
       Object.assign(data,
        {[category.id] : category.name})
    })
    const columns = [ 
      { title: '#', field: 'id'  ,editable:'never'},
      { title: 'Name', field: 'name' },
      {title : 'Descrption' , field : 'description'},
      {title : 'Price' , field : 'price'},
      {title : 'Category' , field : 'category',lookup: data},
      {title : 'Image' , field : 'image', render: rowData => <img  src={ rowData.image} style={{width: 65}}/>},

    ]
     
    
  const rows = this.state.products.map(function(product) {
    return {
      id: product.id,
      name:  product.name,
      description:  product.description,
      price:  product.price,
      category:  product.category.id,
       image:  product.image ?  process.env.REACT_APP_URL+product.image : '',
      }
});
   
    const permissions = this.props.permissions;
    const self = this;
      return (
          
        <div>
              <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>Create</ModalHeader>
            <ModalBody>
            <div
                style={this.state.showLoader ? {} : { display: "none" }}
                className="loader"
              ></div>
              <div
                style={this.state.showBody ? {} : { display: "none" }}
                className="modal-body-content"
              >
                <div className="group-control">
                <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                        required
                          type="text"
                          className="form-control"
                          value={this.state.name}
                          placeholder="Enter Name"
                          onChange={(e)=>{this.setState({name : e.target.value})}}
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Price</label>
                        <input
                        required
                          type="number"
                          className="form-control"
                          value={this.state.price}
                          placeholder="Enter Price"
                          onChange={(e)=>{this.setState({price : e.target.value})}}
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Descrption</label>
                        <input
                        required
                          type="text"
                          className="form-control"
                          value={this.state.description}
                          placeholder="Enter Description"
                          onChange={(e)=>{this.setState({description : e.target.value})}}
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                          <label>Select Category</label>
                      <select
                                required
                                onChange={(e) => this.setState({
                                category_id : e.target.value
                              })}
                              className="form-control"
                              value={this.state.category_id}
                              
                            >
                              <option value={""} disabled>
                                Select....
                              </option>

                              {this.state.categories.map(function(
                                category,
                                i
                              ) {
                                return (
                                  <option key={i} value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                            </select>
                      </div>
                    </div>
                  </div>
                <br />
                <div className="row">
                    <div className="col">
                    <ImageUploader
                        label={`Aceept : png , jpg , jpeg`}
                        withIcon={true}
                        required
                        buttonText='Choose image'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                        singleImage={true} />
                    </div>
                  </div>

               
                
                </div>
              </div>
             </ModalBody>
            <ModalFooter style={this.state.showBody ? {} : { display: "none" }}>
              <input
                type="submit"
                value="Submit"
                color="primary"
                className="btn btn-primary"
              />
              <Button color="danger" onClick={this.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
        
            
          <div className="kt-portlet__head" style={{ height: "auto" }}>
              <div className="kt-portlet__head-label forkt-view">
                <div className="row form-view">
                  {/* <div className="col-md-9">
                                    <input type="text" placeholder="Search ..." id="search" className="form-control" value={this.state.search} style={{ width:'100%' }} onChange={this.handleChangeSearch} />                                    
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn-info btn" onClick={this.handleSubmit}>Search</button>
                                    </div> */}
                </div>
              </div>
              {/* end search form  */}
              <div className="kt-portlet__head-toolbar">
                {
                   <React.Fragment>
                  <CreateProduct
                
                  onCreate={this.getdata}>
                    <i className="la la-plus-circle m--font-brand"></i>
                  </CreateProduct>
                    </React.Fragment>
                }
              </div>
            
            </div>
                  <div className="table-responsive">
              
          
              <MaterialTable
              
                 
                    columns = {columns}
                    data = {rows}
                    title="Products"

                    editable={{
                      onRowDelete: oldData =>
                      this.DeleteProduct(oldData),
                    }}

                    actions={[
                      {
                        icon: 'settings',
                        tooltip: 'Edit',
                        onClick: (event, rowData) => {
                         this.setState({
                           modal : !this.state.modal,
                           name :rowData.name,
                           description :rowData.description,
                           price :rowData.price,
                           product_id : rowData.id,
                           category_id : rowData.category
                         })
                        }
                      }
                    ]}
                    
                    
                />
          </div>
                 
                </div>
              
          
        </div>
      );
    }
  }

const mapStateToProps = (state) => ({
  permissions : state.permissions
})
export default connect(mapStateToProps)(ListProduct);
