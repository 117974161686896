import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import callAPI from "./api";
import {toastr} from 'react-redux-toastr';
import ReactTooltip from "react-tooltip";
export default class CreateAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showBody: true,
      showLoader: true,
     username : '',
     password : '',
     name : ''
    };
   
   
   
  }

  initialState = () => {
    this.setState({
        modal: false,
        showBody: true,
        showLoader: true,
       username : '',
       password : '',
       name : ''
        });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  }
  
  



  handleSubmit = async (event) => {
    event.preventDefault();
   
    this.setState({
      showLoader: true,
      showBody: false
    });
    const body ={
        name : this.state.name,
        username : this.state.username,
        password : this.state.password,
    }
   
    let response =await new callAPI().api("POST", "/users", {}, {}, body);
    if (response.status == 200) {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false
        });
        this.props.onCreate();
        this.initialState();
        toastr.success("User added successfully!");
      } else if (response.status == 422 || response.status == 500) {
        this.setState({
          showLoader: false,
          showBody: true
        });
        toastr.error("invalid data");
      }
  
}
  
   
  render() {
   
     const self = this
    return (
      <div>
        <ReactTooltip />
        <button
          type="button"
          data-tip="Create User"
          className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
          onClick={this.toggle}
        >
         {this.props.children}
        </button>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>Create</ModalHeader>
            <ModalBody>
            <div
                style={this.state.showLoader ? {} : { display: "none" }}
                className="loader"
              ></div>
              <div
                style={this.state.showBody ? {} : { display: "none" }}
                className="modal-body-content"
              >
                <div className="group-control">
                <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                        required
                          type="text"
                          className="form-control"
                          value={this.state.name}
                          placeholder="Enter Name"
                          onChange={(e)=>{this.setState({name : e.target.value})}}
                          
                        />
                      </div>
                    </div>

                    <div className="col">
                      <div className="form-group">
                        <label>UserName</label>
                        <input
                        required
                          type="text"
                          className="form-control"
                          value={this.state.username}
                          placeholder="Enter UserName"
                          onChange={(e)=>{this.setState({username : e.target.value})}}
                          
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                        required
                          type="password"
                          className="form-control"
                          value={this.state.password}
                          placeholder="Enter Password"
                          onChange={(e)=>{this.setState({password : e.target.value})}}
                          
                        />
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
             </ModalBody>
            <ModalFooter style={this.state.showBody ? {} : { display: "none" }}>
              <input
                type="submit"
                value="Submit"
                color="primary"
                className="btn btn-primary"
              />
              <Button color="danger" onClick={this.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}