import React, {Component} from 'react';
var axios  = require('axios');
// import axios from 'axios'
const rondam = {
    data : []
}
class callAPI extends Component {

    constructor(props){
        super(props);
        this.baseURL = process.env.REACT_APP_URL+'/api';
    }
    
    // ******* START API FUNCTION
     api = async (method, url, {}, params, data) =>{
         
        const self = this;
        let res = {};
        const headers = {
            Authorization : 'Bearer '+JSON.parse(localStorage.getItem('user_token')),
            Accept : 'application/json',
            'Content-Type' : 'application/json'
           
        }
     
        if(method == "POST" || method=="PUT")
        {
            
       return await fetch(this.baseURL+url,{
                method : method,
                headers:headers,
                body :JSON.stringify(data),
              
            })
            
        }
        else
        {
           return await fetch(this.baseURL+url,{
                method : method,
                headers: headers,
                params : params
            })
           
        // }
      
    }
}
    refreshToken(){

        return axios({
            method : 'post',
            url    : '/refresh',
            baseURL: this.baseURL,
            headers: {
                Authorization : 'Bearer ' + JSON.parse(localStorage.getItem('user')).token,
            }
        }).then(response => {
            // IF TOKEN REFRESHED
            if(response.status == 200){
                // GET THE CURRENT USER OBJECT FROM LOCAL STORAGE
                const user = JSON.parse(localStorage.getItem('user'));
                // UPDATE TOKEN ITEM IN THE CURRENT USER OBJECT
                user.token = response.data.token;
                // UPDATE USER OBJECT IN LOCAL STORAGE
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(user));
                return { token : response.data.token}
            }
        }).catch(function(error){
            return 0
        });

    }

}

export default callAPI;
