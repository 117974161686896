import React, { Component, Fragment } from "react";
// import callApi from "./api";
 import CreateAsset from "./CreateAsset";
// import { Link } from "react-router-dom";
import "./card.css";
import {toastr} from 'react-redux-toastr';

// import Delete from "../delete/index"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import callAPI from './api'
// import EditProduct from "../edit/index"
import { connect } from "react-redux";
import ReactPlayer from 'react-player'
class ListAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      assets: [],
      categories: [],
      category_id: "",
      description : [],
    };
  }

  componentDidMount() {
    this.getCategories();
  }
  handleInputChild = async (value) => {
    const update = [...this.state.description];
    update[0] = value;
     await this.setState({ description : update });
   };
  getCategories =async () => {
    let response =await new callAPI().api("GET", "/assets-category", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        categories : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  };


  deleteAsset =async(e) => {
   
    e.preventDefault();

  
    let response =await new callAPI().api("DELETE", "/asset/"+e.target.id, {}, {}, {});
    if(response.status == 200)
    {
         toastr.success('Edited Successfully');
         this.getAssetByCategory()
    }
   else if(response.status == 202)
    {
      let error = await response.json()
    toastr.error(error.error)
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  handleSubmit =async(e) =>
  {
    e.preventDefault();
    
 
   const body = {
     description : this.state.description[0],
     assets_category_id : this.state.category_id
   }
   let response =await new callAPI().api("PUT", "/asset/"+e.target.id, {}, {}, body);
   if(response.status == 200)
   {
        toastr.success('Updated Successfully');
        this.getAssetByCategory()
   }
   else 
   {
     toastr.error('SomeThing Went Wrong');
   }
  }
  handleCategoryID = async e => {
    await this.setState({
      category_id: e.target.value
    });
    this.getAssetByCategory();
  };
  getAssetByCategory = async() => {

    let response =await new callAPI().api("GET",  "/assets-by-category-id/" + this.state.category_id, {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        assets: await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
}
  
  render() {
    const permissions = this.props.permissions;
   
      return (
        <div>
          
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
            <div className="kt-portlet__head d-inline-block ">
            <div className="kt-portlet__head-toolbar" style={{marginTop:"10px", float:"right"}}>
                {
                  
                  <CreateAsset
                  >
                    <i className="la la-plus-circle m--font-brand"></i>
                  </CreateAsset>
                }
              </div>
              <div className="py-3">
                <div className="row">
                  <div className="col-md-2">
                    <label className="mt-2">Select Category</label>
                  </div>
                  <div className="col-md-3">
                    <select
                      required
                      onChange={this.handleCategoryID}
                      className="form-control"
                      value={this.state.category_id}
                    >
                      <option value={""} disabled>
                        Select....
                      </option>

                      {this.state.categories.map(function(category, i) {
                        return (
                          <option key={i} value={category.id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {/* end search form  */}
            </div>

            <div className="kt-portlet__body">
              <div className="tab-content">
                {/* begin::Widget */}
                <div className="kt-widget11">
                  {this.state.assets && (
                    <div className="row">
                      {this.state.assets.map((asset, i) => {
                        return (
                          <Fragment key={i}>
                              
                            <div className="col-md-4">
                              {
                             
                                  <div
                                    className="card-boxshadow-hover card-boxshadow-hover p-3 card-random  rounded mb-4 "
                                    style={{
                                      width: "100%",
                                      borderColor: "blue"
                                    }}
                                  >
                                    <div className="row" >
                                      <div className="col-md-4">
                                        {this.state.category_id == 3 ? 
                                        <ReactPlayer 
                                        className="img-fluid mx-auto d-block h-100 w-100"
                                        controls
                                         url={ process.env.REACT_APP_URL +
                                           "/storage/" +
                                           asset.images}/>
                                        :
                                            <img
                                            src={
                                                process.env.REACT_APP_URL +
                                              "/storage/" +
                                              asset.images
                                            }
                                            className="img-fluid mx-auto d-block h-100 w-100"
                                          />
                      }
                                          
                                      </div>
                                      <div className="col-md-8">
                                        <form id={asset.id} onSubmit={this.handleSubmit}>
                                        <div className="group-control">
         
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
            
                            <label>Description</label>
                            <input
                              className='form-control'
                              type="text"
                              placeholder={asset.description}
                               onChange={(e)=> this.handleInputChild(e.target.value)}
                            />
                      
                      </div>
                    </div>
                  </div>
               
                </div>
                <input
                type="submit"
                value="Submit"
                color="primary"
                className="btn btn-primary"
              />
              <Button color="danger" style={{float:"right"}} id={asset.id} onClick={this.deleteAsset}>
                Delete
              </Button>
                                        </form>
                                      {/* <Delete 
                                       onDelete={self.getCountries}
                                  
                                    >
                                      <i className="flaticon-delete"></i>{" "}
                                    </Delete> */}
                                      </div>
                                    
                               
                                    </div>
                         
                                  </div>
                                
                              }
                            
                            </div>
                            
                          </Fragment>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* end::Widget 11 */}
              </div>
            </div>
          </div>
        </div>
      );
    }
  
}
const mapStateToProps = state => ({
  permissions: state.permissions
});
export default connect(mapStateToProps)(ListAssets);
