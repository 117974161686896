import React, { useMemo } from "react";
import { useSelector ,connect} from "react-redux";
import { BrowserRouter,Redirect } from "react-router-dom";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import DownloadFiles from "../../widgets/DownloadFiles";
import NewUsers from "../../widgets/NewUsers";
import LatestUpdates from "../../widgets/LatestUpdates";
import BestSellers from "../../widgets/BestSellers";
import RecentActivities from "../../widgets/RecentActivities";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck"
import callAPI from "../home/google-material/layout/api"

 function Dashboard(props) {
  let date = new Date().toLocaleDateString();
   
 if(date != localStorage.getItem('date'))
   {
    props.logout()
   }

  
   
  return (
    <>

<div>
           
           <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair kt-portlet--height-fluid">
             <div
               className="kt-portlet__head"
               style={{ height: "auto" }}
             >
               <div className="kt-portlet__head-label">
                 <h3 className="kt-portlet__head-title">
                   WoW Mix
                 </h3>
             </div>
   
             </div>

             <div className="kt-portlet__body">
                 
               <div className="tab-content">
                 {/* begin::Widget */}
                 <div className="kt-widget11">
                 
                  
                    
                      
                 
                 </div>
               </div>
             </div>
           </div>
        
       
       {/* end::Widget 11 */}
     </div>
    </>
  );
    //  }
}
const mapStateToProps = (state) => ({
  auth : state.auth
})
export default connect(
  mapStateToProps,
  auth.actions
)(Dashboard)
