import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import callAPI from "./api";
import {toastr} from 'react-redux-toastr';
import ReactTooltip from "react-tooltip";
import ImageUploader from 'react-images-upload';
export default class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showBody: true,
      showLoader: true,
      description : [],
      category_id : "",
      categories :[],
      images : [],
      pictures : [],
     
     
    };
   
    this.getCategories()
   
  }
  onDrop = async (picture,url) =>{
   
   await this.setState({
        pictures: this.state.pictures.concat(picture),
    });
}
  initialState = () => {
    this.setState({
      modal: false,
      showBody: true,
      showLoader: true,
      category_id : "",
      images : [],
      pictures : [],
      description : [],
      title : [],
      button_text : [],
      button_link : []
        });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  }
  
  

  getCategories = async() =>{
   
 
    let response =await new callAPI().api("GET", "/assets-category", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        categories : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if(this.state.pictures.length == 0)
    {
      toastr.error('You Must Choose Pictures');
    }
    else
    {
    this.setState({
      showLoader: true,
      showBody: false
    });
   
    let data = new FormData();
    this.state.pictures.forEach((image_file) => {
      data.append('images[]', image_file,image_file.name);
    });
    this.state.description.forEach((description) => {
      data.append('description[]', description);
    });
    data.append('asset_category_id', this.state.category_id)
    const headers = {
      Authorization : 'Bearer '+JSON.parse(localStorage.getItem('user_token')),
      Accept : 'application/json',
    }
   let res = await fetch(process.env.REACT_APP_URL+'/api/asset',{
      method : 'POST',
      headers:headers,
      body : data,
    
  })
    let response =await res;
    if(response.status == 200)
    {
        this.setState({
            showLoader: false,
            showBody: true,
            modal: false
          });
         toastr.success('Added Successfully');
         this.initialState()
    }
    else if (response.status == 422 || response.status == 500) {
        this.setState({
          showLoader: false,
          showBody: true
        });
        toastr.error("invalid data");
    }
    else 
    {
        this.setState({
            showLoader: false,
            showBody: true });
      toastr.error('SomeThing Went Wrong');
    }
}
}
  handleInputChild = async (value,i) => {
   const update = [...this.state.description];
   update[i] = value;
    await this.setState({ description : update });
  };
  handleTitle = async (value,i) => {
    const update = [...this.state.title];
    update[i] = value;
     await this.setState({ title : update });
   };
   handleButtonLink = async (value,i) => {
    const update = [...this.state.button_link];
    update[i] = value;
     await this.setState({ button_link : update });
   };
   handleButtonText = async (value,i) => {
    const update = [...this.state.button_text];
    update[i] = value;
     await this.setState({ button_text : update });
   };
   
  render() {
     const self = this
    return (
      <div>
        <ReactTooltip />
        <button
          type="button"
          data-tip="Create Asset"
          className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
          onClick={this.toggle}
        >
         {this.props.children}
        </button>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>Create</ModalHeader>
            <ModalBody>
            <div
                style={this.state.showLoader ? {} : { display: "none" }}
                className="loader"
              ></div>
              <div
                style={this.state.showBody ? {} : { display: "none" }}
                className="modal-body-content"
              >
                <div className="group-control">
                <div className="row">
                    <div className="col">
                      <div className="form-group">
                          <label>Select Assets Category</label>
                          <select
                                required
                                onChange={(e) => this.setState({
                                category_id : e.target.value
                              })}
                              className="form-control"
                              value={this.state.category_id}
                              
                            >
                              <option value={""} disabled>
                                Select....
                              </option>

                              {this.state.categories.map(function(
                                category,
                                i
                              ) {
                                return (
                                  <option key={i} value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                            </select>
                      </div>
                    </div>
                  </div>
                  {this.state.category_id &&
                  <div className="row">
                    <div className="col">
                    
                       {this.state.category_id == 1 ?
                        <ImageUploader
                        label={`Aceept : png , jpg , jpeg , Dimension : 800 × 533`}
                        withIcon={true}
                        required
                      //  style={{width:"300px",hight:"300px"}}
                        buttonText='Choose images'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                    />
                    : this.state.category_id == 2 ?

                      <ImageUploader
                        label={`Aceept : png , jpg , jpeg , Dimension : 580 × 900`}
                        withIcon={true}
                        required
                      //  style={{width:"300px",hight:"300px"}}
                        buttonText='Choose images'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                    /> : this.state.category_id == 3 ?<ImageUploader
                    label={`Aceept : Video `}
                    withIcon={true}
                    required
                      accept="video/*"
                    buttonText='Choose Video'
                    onChange={this.onDrop}
                    imgExtension={['.webm', '.mp4']}
                    singleImage
                />
                    :<div></div>
                       }
                    </div>
  </div> }
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        
                    
                    {
                      this.state.pictures.map(function (value,i) {
                          return(
                            <React.Fragment
                            key={i}>
                             
                            <label>Description</label>
                            <input
                              className='form-control'
                              type="text"
                              required
                              placeholder={`Enter description ${i+1}`}
                              onChange={(e)=> self.handleInputChild(e.target.value,i)}
                            />
                          
                            </React.Fragment>
                          )
                          
                    })
                  }
                      
                      </div>
                    </div>
                  </div>
                <br />

               
                
                </div>
              </div>
             </ModalBody>
            <ModalFooter style={this.state.showBody ? {} : { display: "none" }}>
              <input
                type="submit"
                value="Submit"
                color="primary"
                className="btn btn-primary"
              />
              <Button color="danger" onClick={this.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}