import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Country from "./layout/country";
import City from "./layout/City";
import Store from "./layout/Store";
import Franchise from "./layout/Franchise";
import Contact from "./layout/Contact";
import Asset from "./layout/Asset";
import ListProductCategory from "./layout/ProductCategory"
import product from './layout/product';
import User from './layout/User';
import franchiseDetails from './layout/franchiseDetails';

export default function GoogleMaterialPage() {
  return (
    <Switch>
      <Redirect
        exact={true}
        from="/wow"
        to="/dashboard"
      />

      {/* Layout */}
      <Route 
        path="/wow/product" 
        component={product} 
      />
      <Route
        exact path="/wow/franchise/:id" 
        component ={franchiseDetails}
      />
        <Route 
        path="/wow/user" 
        component={User} 
      />
        <Route 
        path="/wow/assets" 
        component={Asset} 
      />
       <Route 
        path="/wow/product-category" 
        component={ListProductCategory} 
      />
      <Route
        path="/wow/users"
        component={Country}
      />
      <Route
        path="/wow/store"
        component={Store}
      />
      <Route
        path="/wow/franchise"
        component={Franchise}
      />
       <Route
        path="/wow/country"
        component={Country}
      />
       <Route
        path="/wow/city"
        component={City}
      />
         <Route
        path="/wow/contact"
        component={Contact}
      />
     
    </Switch>
  );
}
