import React, { Component } from 'react';
import callAPI              from './api';
import {connect}  from 'react-redux'
import Create from './CreateUser'
import {toastr} from 'react-redux-toastr';
import MaterialTable from "material-table";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import callAPI from './api';
import Select  from 'react-select';
// import callAPIWorkspace     from '../../../../workspace/component/api';
// import ReactTable           from 'react-table';
// styles
// import 'react-table/react-table.css';
//component
// import CreateUserModal      from '../create/index';
// import EditUser             from '../edit/index';
// import DeleteUser           from '../delete/index';
// import AssignRole           from '../assignRole/index';
// import AssignPermissions    from '../assignPermission/index';
//css file

class ListUsers extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            modal         : false,
            showBody      : false,
            loading       : true,
            permissionmodal : false,
            permissionshowBody      : false,
            selectedOption : [],
            selectedOptionpermission : [],
            id : '',
            name : '',
            roles : [],
            users : [],
            permissions : []
        };
    }
    componentDidMount()
    {   
        this.getData();
    }
    getUserRoles = async() =>{
      let response =await new callAPI().api("GET", '/users-roles/'+this.state.id, {}, {}, {});
      if(response.status == 200)
      {
        const op = await response.json();
          let selectedOption = op.map(role=>({value:role,label:role}))
        this.setState({
          selectedOption,
          
        })     
      }
      else 
      {
        toastr.error('SomeThing Went Wrong');
      }

  }
    DeleteUser = async(oldData)=>
    {
        let response =await new callAPI().api("DELETE", "/users/"+oldData.id, {}, {}, {});
        if(response.status == 200)
        {
             toastr.success('Deleted Successfully');
             this.getData()
        }
        else if(response.status == 202)
        {
          let error = await response.json()
          toastr.error(error.error)
        }
        else 
        {
          toastr.error('SomeThing Went Wrong');
        }
    }
    UpdateUser = async(newData,oldData)=>{
        if(newData.name == '')
        {
            toastr.error('Name Field Is Required');
        }
        else 
        {
            const body = {
                name : newData.name
            }
            let response =await new callAPI().api("PUT", "/users/"+oldData.id, {}, {}, body);
            if(response.status == 200)
            {
                 toastr.success('Updated Successfully');
                 this.getData()
            }
            else if(response.status == 202)
            {
              let error = await response.json()
              toastr.error(error.error)
            }
            else 
            {
              toastr.error('SomeThing Went Wrong');
            }
        }
      
    }
    getUserPermissions = async() =>{
     
     let response= new callAPI().api('GET', '/users-permissions/'+this.state.id, {}, {}, {});
     if(response.status == 200)
     {
       let res = await response.json()
      let selectedOptionpermission = res.map(permission=>({value:permission,label:permission}))
      this.setState({
        selectedOptionpermission,
      })
     }
         
   

  }
  Permissiontoggle = () =>{
    this.setState({ permissionmodal: !this.state.permissionmodal });
}

handlePermission = (selectedOptionpermission) =>{
    this.setState({ selectedOptionpermission })
}
    getData = async() =>{
       
        let response =await new callAPI().api("GET", "/users", {}, {}, {});
        if(response.status == 200)
        {
          this.setState({
            users : await response.json()
            
          })     
        }
        else 
        {
          toastr.error('SomeThing Went Wrong');
        }

        let rol = await new callAPI().api('GET', '/roles', {} , {}, {});
        if(rol.status == 200)
        {
            let roles =await rol.json()
            this.setState({
            roles : roles.roles
            })     
        }
        else 
        {
            toastr.error('SomeThing Went Wrong');
        }

        let per =await new callAPI().api('GET', '/permissions', {}, {}, {});
        if(per.status == 200)
        {
          let permissions =await per.json()
            this.setState({
            permissions : permissions.permissions,
            loading : false
            })     
        }
        else 
        {
            toastr.error('SomeThing Went Wrong');
        }

 }
    toggle = () =>{
        this.setState({ modal: !this.state.modal });
    }
    handleRole = (selectedOption) =>{
      this.setState({ selectedOption })
  }
  handlePermissionSubmit = async(event) => {

    event.preventDefault();
    this.setState({
        loading  : true,
        showBody    : false,
    })
    let selectedPermissions = this.state.selectedOptionpermission.map(p=>(p.value))
    const body = {
        userId       : this.state.id,
        permissions  : selectedPermissions,
    }
    
    
   let per =await  new callAPI().api('PUT', '/permissions', {}, {}, body);
   if(per.status == 200){
    this.setState({ 
       loading    : false,
        permissionmodal : false,
        selectedOption : null,
        showBody :true
        });
        toastr.success('Permissions Added successfully');             
    }else{
        toastr.error('already exists');
    } 
    
    
}   

  handleSubmit = async (event) =>{

    event.preventDefault();
    if(!this.state.selectedOption)
    {
      toastr.error('You Must Choose Role')
    }
    else {
    let selectedPermissions = this.state.selectedOption.map(p=>(p.value))
    const body = {
        roleNames : selectedPermissions,
        userId   : this.state.id
    }
    let response =await new callAPI().api("POST", "/roles/addRoleToUser", {}, {}, body);  
       if(response.status == 200){
            this.setState({ 
                modal         : false,
                selectedOption : null
            });
            toastr.success('Roles Added successfully');             
        }else{
            toastr.error('already exists');
        } 
      }
}   

    render(){
        
       const permissions = this.state.permissions.map(item=>({value:item ,label:item}))
             const options = this.state.roles.map(role=>({value:role.name ,label:role.name}))
       
        const columns = [ 
            { title: '#', field: 'id'  ,editable:'never'},
            { title: 'Name', field: 'name' },
            {title : 'UserName' , field : 'username', editable:'never'},
          ]
        const rows    = this.state.users.map(function(user){
            return { 
                    id         : user.id,
                    name       : user.name,
                    username   : user.username,
                    }
        })
            return(
                <div>
                    <div>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
          <div className="kt-portlet__head" style={{ height: "auto" }}>
              <div className="kt-portlet__head-label forkt-view">
                <div className="row form-view">
                  {/* <div className="col-md-9">
                                    <input type="text" placeholder="Search ..." id="search" className="form-control" value={this.state.search} style={{ width:'100%' }} onChange={this.handleChangeSearch} />                                    
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn-info btn" onClick={this.handleSubmit}>Search</button>
                                    </div> */}
                </div>
              </div>
              {/* end search form  */}
              <div className="kt-portlet__head-toolbar">
                {
                   <React.Fragment>
                  <Create
                
                  onCreate={this.getData}>
                    <i className="la la-plus-circle m--font-brand"></i>
                  </Create>
                    </React.Fragment>
                }
              </div>
            
            </div>
            <Modal isOpen={this.state.permissionmodal} toggle={this.Permissiontoggle} >
            <form onSubmit={this.handlePermissionSubmit}>
            <ModalHeader>Assign Permissions</ModalHeader>
            <ModalBody>
                <div style={this.state.loading ? {} : {}} className="loader"></div>
                <div style={this.state.loading ? {display:'none'} : {}} className="modal-body-content">
                
                    <div className="group-control">
                        <div className="row">
                            <div className="col">
                                <label>Permissions</label>
                                <Select
                                    value={this.state.selectedOptionpermission}
                                    onChange={this.handlePermission}
                                    options={permissions}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                />
                                
                            </div>  
                        </div>
                    </div>
                    
                </div>
            </ModalBody>
            <ModalFooter>
                <input type="submit" value="Submit" color="primary" className="btn btn-primary" />
                <Button color="danger" onClick={this.Permissiontoggle}>Cancel</Button>
            </ModalFooter>
            </form>
            </Modal>

            <Modal isOpen={this.state.modal} toggle={this.toggle} >
            <form onSubmit={this.handleSubmit}>
            <ModalHeader>Assign Role</ModalHeader>
            <ModalBody>
                {
                    this.state.loading ? 'wait ...':
                    <React.Fragment>
                        <h6>Assign role to {this.state.name}</h6>
                        <div className="group-control">
                            <label>Role</label>
                              
                                <Select
                                value={this.state.selectedOption}
                                onChange={this.handleRole}
                                options={options}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                /> 
                        </div>
                    </React.Fragment>
                }  
            </ModalBody>
            <ModalFooter >
                <input type="submit" value="Submit" color="primary" className="btn btn-primary" />
                <Button color="danger" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
            </form>
            </Modal>
                  <div className="table-responsive">      
              <MaterialTable
               actions={[
                {
                  icon: 'addcircle',
                  tooltip: 'Assign Role',
                  onClick: async(event, rowData) => {
                   await this.setState({
                     modal : !this.state.modal,
                      id : rowData.id,
                      name : rowData.name
                   })
                   this.getUserRoles()
                  }
                 
                },
                {
                  icon: 'addcircle',
                  tooltip: 'Assign Permission',
                  onClick: async(event, rowData) => {
                   await this.setState({
                     permissionmodal : !this.state.permissionmodal,
                      id : rowData.id,
                      name : rowData.name
                   })
                   this.getUserPermissions()
                  }
                 
                }
              ]}
                    columns = {columns}
                    data = {rows}
                    title="Users" 
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                         this.UpdateUser(newData,oldData),
                        onRowDelete: oldData =>
                        this.DeleteUser(oldData),
                      }}
                />
          </div>
                 
                </div>
              
          
        </div>
                </div>
            );
        }

    
}
const mapStateToProps = (state) => ({
    permissions : state.permissions
})
export default connect(mapStateToProps)(ListUsers);