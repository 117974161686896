import React, { Component } from "react";
import {connect} from 'react-redux';
import { Table } from "react-bootstrap";
import callAPI from './api';
import { Link } from "react-router-dom";

import {toastr} from 'react-redux-toastr';
import MaterialTable from "material-table";
class ListFranchise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      franchises : [],
     
    };
  }
  
  componentDidMount()
  {
    
  this.getFranchises()
    
  }
 
  getFranchises= async() =>
  {
    let response =await new callAPI().api("GET", "/franchise", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        franchises : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }


  HandleF = (e,data) =>{
    e.preventDefault();
  }

  render() {
  
  const columns = [ { field: "id", title: "#" },
    { field: "first", title: "FirstName" },
    { field: "sur", title: "SurName" },
    { field: "email", title: "Email" },
    { field: "country", title: "Country" },
    { field: "city", title: "City" },
    { field: "mobile", title: "Mobile" },
   
  ]
   
    
    const rows = this.state.franchises.map(function(franchise) {
      return {
        first: franchise.personal_info.firstname,
        sur: franchise.personal_info.surname,
        country: franchise.personal_info.country,
        city: franchise.personal_info.city,
        id: franchise.id,
        mobile:  franchise.personal_info.mobile_number,
        email:  franchise.personal_info.email,
      }
    })
   
    const permissions = this.props.permissions;
    const self = this;
      return (
        <div>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
                  <div className="table-responsive">      
              <MaterialTable
                    columns = {columns}
                    data = {rows}
                    title="Franchises" 
                    actions ={[
                      rowData => ({
                        icon : () => <Link to={{ pathname :"/wow/franchise/"+rowData.id }} className="btn btn-outline-brand btn-elevate btn-circle btn-icon">
                        <i className="flaticon-eye"></i>         
                        </Link>  ,
                        tooltip: 'View',
                       
                      })
                    ]}
                />
          </div>
                 
                </div>
              
          
        </div>
      );
    }
  }

const mapStateToProps = (state) => ({
  permissions : state.permissions
})
export default connect(mapStateToProps)(ListFranchise);
