export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        permission : 'VIEW_USERS'
      },
      {
        title: "Users",
        root: true,
        alignment: "left",
        toggle: "click",
        page: "wow/user",
        permission : 'VIEW_USERS'

      },
     
      {
        title: "Assets",
        root: true,
        alignment: "left",
        toggle: "click",
        permission : 'VIEW_ASSETS',
        submenu: [
          {
            title: "Assets",
            bullet: "dot",
            page: "wow/assets",
        
          },
        ]
      },
      {
        title: "Products",
        root: true,
        permission : 'VIEW_PRODUCT',
        alignment: "left",
        toggle: "click",
        submenu: [
          {
            title: "Products",
            bullet: "dot",
            page: "wow/product",
        
          },
          {
            title: "Product Categories",
            bullet: "dot",
            page: "wow/product-category",
        
          }
        ]
      },
      {
        title: "About",
        root: true,
        alignment: "left",
        permission : 'VIEW_COUNTRY',
        toggle: "click",
        submenu: [
          {
            title: "Countries",
            bullet: "dot",
            page: "wow/country",
        
          },
          {
            title: "Cities",
            bullet: "dot",
            page: "wow/city",

          },
          {
            title: "Stores",
            bullet: "dot",
            page: "wow/store",

          },
          {
            title: "Franchises",
            bullet: "dot",
            page: "wow/franchise",

          },
          {
            title: "Contacts",
            bullet: "dot",
            page: "wow/contact",

          },
        ]
      },
    ]},
      
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        permission : '',

        bullet: "dot"
      },
    
      { section: "Components" },
      {
        title: "Users",
        root: true,
        bullet: "dot",
        permission : 'VIEW_USERS',

        icon: "flaticon2-browser-2",
        page: "wow/user",
        
      },
      {
        title: "Assets",
        root: true,
        bullet: "dot",
        permission : 'VIEW_ASSETS',

        icon: "flaticon2-browser-2",
        submenu: [
          {
            title: "Assets",
            bullet: "dot",
            page: "wow/assets"

         
          },
         
        
        ]
      },
      {
        title: "Products",
        root: true,
        permission : 'VIEW_PRODUCT',

        bullet: "dot",
        icon: "flaticon2-browser-2",
       
        submenu: [
          {
            title: "Products",
            bullet: "dot",
            page: "wow/product",
        
          },
          {
            title: "Product Categories",
            bullet: "dot",
            page: "wow/product-category",
        
          }
        ]
      },

      {
        title: "About",
        root: true,
        bullet: "dot",
        permission : 'VIEW_COUNTRY',
        icon: "flaticon2-browser-2",
        submenu: [
          {
            title: "Countries",
            bullet: "dot",
            page: "wow/country"

         
          },
          {
            title: "Cities",
            bullet: "dot",
            page: "wow/city"

          
          },
          {
            title: "Stores",
            bullet: "dot",
            page: "wow/store"

          
          },
         
          {
            title: "Franchises",
            bullet: "dot",
            page: "wow/franchise",

          },
          {
            title: "Contact",
            bullet: "dot",
            page: "wow/contact",

          },
          
        ]
      },
    ]}
};
