import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import callAPI from "./api";
import ReactTooltip from "react-tooltip";
import ImageUploader from 'react-images-upload';
import {toastr} from 'react-redux-toastr';

export default class CreateProductCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      showBody: true,
      showLoader: true,
      name : "",
      pictures : []
    
     
    };
   
   
  }
  onDrop = async (picture,url) =>{
   
    await this.setState({
         pictures: this.state.pictures.concat(picture),
     });
 }
  initialState = () => {
    this.setState({
      modal: false,
      showBody: true,
      showLoader: true,
      name : "",
     pictures : []

        });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  }

  handleSubmit =async (event) => {
    event.preventDefault();
    if(this.state.pictures.length == 0)
    {
      toastr.error('You Must Choose Pictures');
    }
    else
    {
    this.setState({
      showLoader: true,
      showBody: false
    });
  
    let data = new FormData();
    this.state.pictures.forEach((image_file) => {
      data.append('image', image_file,image_file.name);
    });
   
      data.append('name', this.state.name);
      const headers = {
        Authorization : 'Bearer '+JSON.parse(localStorage.getItem('user_token')),
        Accept : 'application/json',
      }
     let res = await fetch(process.env.REACT_APP_URL+'/api/product-category',{
        method : 'POST',
        headers:headers,
        body : data,
      
    })
      let response =await res;
   if (response.status == 200) {
        this.setState({
          showLoader: false,
          showBody: true,
          modal: false
        });
        this.props.onCreate();
        this.initialState();
        toastr.success("Product Category added successfully!");
      } else if (response.status == 422 || response.status == 500) {
        this.setState({
          showLoader: false,
          showBody: true
        });
        toastr.error("invalid data");
      }
    
  }
  }

  render() {
  
    return (
      <div>
        <ReactTooltip />
        <button
          type="button"
          data-tip="Create Product Category"
          className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
          onClick={this.toggle}
        >
         {this.props.children}
        </button>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <form onSubmit={this.handleSubmit}>
            <ModalHeader>Create</ModalHeader>
            <ModalBody>
            <div
                style={this.state.showLoader ? {} : { display: "none" }}
                className="loader"
              ></div>
              <div
                style={this.state.showBody ? {} : { display: "none" }}
                className="modal-body-content"
              >
                <div className="group-control">
                <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                        required
                          type="text"
                          className="form-control"
                          value={this.state.name}
                          placeholder="Enter Name"
                          onChange={(e)=>{this.setState({name : e.target.value})}}
                          
                        />
                      </div>
                    </div>
                  </div>
                <br />
                <div className="row">
                    <div className="col">
                    <ImageUploader
                        label={`Aceept : png , jpg , jpeg`}
                        withIcon={true}
                        required
                        buttonText='Choose image'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                        singleImage={true} />
                    </div>
                  </div>

               
                
                </div>
              </div>
             </ModalBody>
            <ModalFooter style={this.state.showBody ? {} : { display: "none" }}>
              <input
                type="submit"
                value="Submit"
                color="primary"
                className="btn btn-primary"
              />
              <Button color="danger" onClick={this.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}