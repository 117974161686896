import React, { Component } from "react";
import {connect} from 'react-redux';
import { Table } from "react-bootstrap";
import callAPI from './api';
import {toastr} from 'react-redux-toastr';
import MaterialTable from "material-table";
class ListContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contacts : [],
     
    };
  }
  
  componentDidMount()
  {
    
  this.getContacts()
    
  }
 
  getContacts= async() =>
  {
    let response =await new callAPI().api("GET", "/contact", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        contacts : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }


 

  render() {
  
  const columns = [ 
    { field: "id", title: "#" },
    { field: "name", title: "Name" },
    { field: "email", title: "Email" },
    { field: "subject", title: "Subject" },
    { field: "message", title: "Message" },
  ]
   
    
    const rows = this.state.contacts.map(function(contact) {
      return {
        id: contact.id,
              name:  contact.name,
              email: contact.email,
              subject: contact.subject,
              message: contact.message,
      }
    })
   
    const permissions = this.props.permissions;
    const self = this;
      return (
        <div>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
                  <div className="table-responsive">      
              <MaterialTable
                    columns = {columns}
                    data = {rows}
                    title="Contacts" 
                />
          </div>
                 
                </div>
              
          
        </div>
      );
    }
  }

const mapStateToProps = (state) => ({
  permissions : state.permissions
})
export default connect(mapStateToProps)(ListContact);
