import React, { Component } from "react";
import {connect} from 'react-redux';
import { Table } from "react-bootstrap";
import callAPI from './api';
import {toastr} from 'react-redux-toastr';
import MaterialTable from "material-table";
import { lookup } from "dns";
class ListCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stores : [],
     cities :[]
    };
  }
  
  componentDidMount()
  {
    
    this.getdata()
    this.getCities()
  }
  getCities= async() =>
  {
    let response =await new callAPI().api("GET", "/city", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        cities : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }
  getdata= async() =>
  {
    let response =await new callAPI().api("GET", "/store", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        stores : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }
  CreateStore = async(store) =>
  {
    if(store.address == "" || store.description == "" || store.lat == "" || store.long == "")
    {
      toastr.error("All Fields Is Required")
    }
    else
    {
    const body = {
      'description' : store.description,
      'address' : store.address,
      'lat' : store.lat,
      'long' : store.long,
      'city_id' : store.city_id
    }
    let response =await new callAPI().api("POST", "/store", {}, {}, body);
    if(response.status == 200)
    {
         toastr.success('Added Successfully');
         this.getdata()
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  }
  UpdateStore = async(newData ,oldData)=>
  {
    if(newData.address == "" || newData.description == "" || newData.lat == "" || newData.long == "")
    {
      toastr.error("All Fields Is Required")
    }
   else{
    const body = {
      'description' : newData.description,
      'address' : newData.address,
      'lat' : newData.lat,
      'long' : newData.long,
      'city_id' : newData.city_id
    }
    let response =await new callAPI().api("PUT", "/store/"+oldData.id, {}, {}, body);
    if(response.status == 200)
    {
         toastr.success('Updated Successfully');
         this.getdata()
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  }
  DeleteStore = async(store) =>
  {
   
    let response =await new callAPI().api("DELETE", "/store/"+store.id, {}, {}, {});
    if(response.status == 200)
    {
         toastr.success('Deleted Successfully');
         this.getdata()
    }
    else if(response.status == 202)
    {
      let error = await response.json()
      toastr.error(error.error)
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }


 

  render() {
    let data = {}
    this.state.cities.map((city)=>{
         Object.assign(data,
          {[city.id] : city.name})
      })
    const columns = [ 
      { title: '#', field: 'id'  ,editable:'never'},
      { title: 'Address', field: 'address' },
      { title: 'Description', field: 'description' },
      { title: 'City', field: 'city_id' ,lookup:data },
      { title: 'Long', field: 'long' },
      { title: 'Lat', field: 'lat' },
    ]
     
    
  const rows = this.state.stores.map(function(store) {
    return {
      id: store.id,
      address:  store.address,
      description:  store.description,
      city_id:  store.city.id,
      lat:  store.lat,
      long:  store.long,
      }
});
   
    const permissions = this.props.permissions;
    const self = this;
      return (
        <div>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
        
            
           
                  <div className="table-responsive">
              
          
              <MaterialTable
                    columns = {columns}
                    data = {rows}
                    title="Stores"
                    editable={{
                      onRowAdd: newData =>
                        this.CreateStore(newData),
                      onRowUpdate: (newData, oldData) =>
                       this.UpdateStore(newData,oldData),
                      onRowDelete: oldData =>
                      this.DeleteStore(oldData),
                    }}
                    
                />
          </div>
                 
                </div>
              
          
        </div>
      );
    }
  }

const mapStateToProps = (state) => ({
  permissions : state.permissions
})
export default connect(mapStateToProps)(ListCountry);
