import React, { Component } from "react";
import {connect} from 'react-redux';
import { Table } from "react-bootstrap";
import callAPI from './api';
import {toastr} from 'react-redux-toastr';
import MaterialTable from "material-table";
class listCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cities : [],
      countries : [],
     
    };
  }
  
  componentDidMount()
  {
    
  this.getCities()
  this.getCountries()
    
  }
  getCountries= async() =>
  {
    let response =await new callAPI().api("GET", "/country", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        countries : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }
  getCities= async() =>
  {
    let response =await new callAPI().api("GET", "/city", {}, {}, {});
    if(response.status == 200)
    {
      this.setState({
        cities : await response.json()
        
      })     
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
   
  }
  CreateCity = async(city) =>
  {
      if(city.name == "")
      {
          toastr.error("Name Field Can't be empty")
      }
      else{
    const body = {
      'name' : city.name,
      'country_id' : city.country_id
     
    }
    
    let response =await new callAPI().api("POST", "/city", {}, {}, body);
    if(response.status == 200)
    {
         toastr.success('Added Successfully');
         this.getCities()
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
  }
  UpdateCity = async(newData ,oldData)=>
  {
   if(newData.name == "")
   {
     toastr.error("Name Field Can't be Empty")
   }
   else
   {
    const body = {
      'name' : newData.name
    }
    let response =await new callAPI().api("PUT", "/city/"+oldData.id, {}, {}, body);
    if(response.status == 200)
    {
         toastr.success('Updated Successfully');
         this.getCities()
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }
}
  DeleteCity = async(country) =>
  {
   
    let response =await new callAPI().api("DELETE", "/country/"+country.id, {}, {}, {});
    if(response.status == 200)
    {
         toastr.success('City Deleted Successfully');
         this.getCities()
    }
    if(response.status == 202)
    {
      let error = await response.json()
      toastr.error(error.error)
    }
    else 
    {
      toastr.error('SomeThing Went Wrong');
    }
  }


 

  render() {
      
    let data = {}
  this.state.countries.map((country)=>{
       Object.assign(data,
        {[country.id] : country.name})
    })
  
    const columns = [ 
        { title: '#', field: 'id' ,editable:'never'},
      { title: 'Name', field: 'name' },
      { title: 'Country', field: 'country_id' ,lookup: data}]
    
  const rows = this.state.cities.map(function(city) {
    return {
      id: city.id,
      name:  city.name,
      country_id : city.country.id
      }
});
   
    const permissions = this.props.permissions;
    const self = this;
      return (
        <div>
          <div className="kt-portlet kt-portlet--full-height  kt-portlet--unair">
                  <div className="table-responsive">      
              <MaterialTable
                    columns = {columns}
                    data = {rows}
                    title="Cities"
                    editable={{
                    
                      onRowAdd: newData =>
                        this.CreateCity(newData),
                      onRowUpdate: (newData, oldData) =>
                       this.UpdateCity(newData,oldData),
                      onRowDelete: oldData =>
                      this.DeleteCity(oldData),
                    }}
                    
                />
          </div>
                 
                </div>
              
          
        </div>
      );
    }
  }

const mapStateToProps = (state) => ({
  permissions : state.permissions
})
export default connect(mapStateToProps)(listCity);
